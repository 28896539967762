import 'styles/reset.css'
import 'styles/globals.css'
import 'styles/cursor.css'

import 'lenis/dist/lenis.css'
import 'atropos/css'

import '@fortawesome/fontawesome-svg-core/styles.css'

import { config } from '@fortawesome/fontawesome-svg-core'

config.autoAddCss = false

function MyApp({ Component, pageProps }) {
  return <Component {...pageProps} />
}

export default MyApp
